import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BannerCard from '../../BannerCard';
import { themeActions } from '../../../_actions/theme.actions';
import DeleteSectionButton from '../../DeleteSectionButton';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  }
}));

export default function Banner({ data, showDeleteButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, `options[${property}]`, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleChangeValues = (values) => {
    let newData = JSON.parse(JSON.stringify(data));
    for(const item of values){
      _.set(newData, `options[${item.property}]`, item.value)
    }
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleMultipleChange = (changedData) => {
    let newData = {...data, options: {...data.options, ...changedData}}
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <BannerCard 
          data={data.options} 
          onChange={handleChange}
          onChangeValues={handleChangeValues}
          onMultipleChange={handleMultipleChange} 
          showWideRatio 
        />

        {showDeleteButton && <DeleteSectionButton />}
    </main>
  );
}