import { apiFetcher } from "./apiFetcher";
import _ from 'lodash'

export const categoriesService = {
  getAllCategories
};

function getAllCategories() {
  const url = `category`

  return apiFetcher(url, { method: "GET" });
}