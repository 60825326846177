import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card, CardContent, Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, Select, TextField } from '@material-ui/core';
import DeleteSectionButton from '../../DeleteSectionButton';
import ColorPicker from '../../ColorPicker';
import { themeActions } from '../../../_actions/theme.actions';
import _ from 'lodash';
import CategoryPicker from '../../CategoryPicker';
import ProductPicker from '../../ProductPicker';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  }
}));

export default function TextBlock({ data, showDeleteButton }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleChangeValues = (values) => {
    let newData = JSON.parse(JSON.stringify(data));
    for(const item of values){
      _.set(newData, item.property, item.value)
    }
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>

            <ColorPicker 
              label='Cor do título' 
              initialColor={data.options.titleColor} 
              onChange={(color) => handleChange('options.titleColor', color)} 
            />

            <ColorPicker 
              label='Cor do subtítulo' 
              initialColor={data.options.subtitleColor} 
              onChange={(color) => handleChange('options.subtitleColor', color)} 
            />

            <ColorPicker 
              label='Cor do texto' 
              initialColor={data.options.textColor} 
              onChange={(color) => handleChange('options.textColor', color)} 
            />

            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Título"
                  value={data.options.title || ''} 
                  onChange={(e) => handleChange('options.title', e.target.value)} 
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Subtítulo"
                  value={data.options.subtitle || ''} 
                  onChange={(e) => handleChange('options.subtitle', e.target.value)} 
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <TextField 
                  label="Texto" 
                  multiline 
                  rows={4} 
                  variant="outlined" 
                  value={data.options.text} 
                  onChange={(e) => handleChange('options.text', e.target.value)} 
                />
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <InputLabel>Alinhamento dos textos</InputLabel>
                <Select
                    native
                    value={data.options.textAlign || ''}
                    onChange={(e) => handleChange('options.textAlign', e.target.value)}
                >
                    <option value='left'>Alinhar à esquerda</option>
                    <option value='center'>Centralizar</option>
                    <option value='right'>Alinhar à direita</option>
                </Select>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                    control={
                        <Checkbox
                        checked={_.isNil(data.options.showButton) ? false : data.options.showButton}
                        onChange={() => handleChange('options.showButton', !data.options.showButton)} 
                        color="primary"
                        />
                    }
                    label='Exibir botão'
                />
            </FormControl>

            {data.options.showButton &&
                <>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField 
                            label="Texto do botão"
                            value={data.options.buttonText || ''} 
                            onChange={(e) => handleChange('options.buttonText', e.target.value)} 
                        />
                    </FormControl>

                    <FormControl fullWidth  className={classes.formControl}>
                        <InputLabel>Ação</InputLabel>
                        <Select
                            native
                            value={data.options.action}
                            onChange={(e) => handleChange('options.action', e.target.value)} 
                            label="Ação"
                            inputProps={{ name: 'action' }}
                        >
                            <option value='noAction'>Sem ação</option>
                            <option value='goToProduct'>Ir para um produto</option>
                            <option value='goToCategory'>Ir para uma categoria de produtos</option>
                            <option value='goToUrl'>Abrir um link externo</option>
                        </Select>
                        <FormHelperText>Selecione a ação que irá acontecer ao clicar neste botão</FormHelperText>
                    </FormControl>
                    
                    {data.options.action === 'goToCategory' &&
                        <FormControl fullWidth className={classes.formControl}>
                            <CategoryPicker
                                selectedCategoryId={data.options.categoryId || ''}
                                onPicked={(category) => handleChange('options.categoryId', category.id)} 
                            />
                            <FormHelperText>Selecione a categoria que será exibida ao clicar no botão</FormHelperText>
                        </FormControl>
                    }

                    {data.options.action === 'goToProduct' &&
                        <FormControl fullWidth className={classes.formControl}>
                            <ProductPicker
                                selectedProductId={data.options.productId || ''}
                                onPicked={(product) => {
                                  handleChangeValues([
                                    { property: 'options.productId', value: product.id },
                                    { property: 'options.productUrl', value: product.url }
                                  ])
                                }} 
                            />
                            <FormHelperText>Selecione o produto que será exibido ao clicar no botão</FormHelperText>
                        </FormControl>
                    }

                    {data.options.action === 'goToUrl' &&
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField 
                                label="Link"
                                value={data.options.link || ''}
                                onChange={(e) => handleChange('options.link', e.target.value)} 
                            />
                            <FormHelperText>Insira o link que será aberto no navegador do celular ao clicar no botão. Ex.: https://www.minhaloja.com.br/promocao</FormHelperText>
                        </FormControl>
                    }
                </>
            }

          </CardContent>
      </Card>

      {showDeleteButton && <DeleteSectionButton />}
    </main>
  );
}