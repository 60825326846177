import { apiFetcher } from "./apiFetcher";
import _ from "lodash";

export const productsService = {
  getProducts,
  getProductById,
};

function getProducts(filters) {
  const baseProductsByCategoryUrl = `product/category/${filters.category_id}`;

  const baseUrl = filters.category_id ? baseProductsByCategoryUrl : `product`
  
  const pagingArray = [];
  pagingArray.push(`page=${filters.page ? filters.page : 1}`);
  pagingArray.push(`limit=${filters.per_page}`);

  const sortArray = filters.sort_by ? sortAdapter(filters.sort_by) : []

  const url = queryStringsAdapter(baseUrl, pagingArray.concat(sortArray));

  return apiFetcher(
    url,
    { method: "GET" },
    1,
    true
  );
}

function getProductById(productId) {
  const url = `product/${productId}`
  
  return apiFetcher(url, {
    method: "GET"
  })
}

export default function queryStringsAdapter(url, paramsStringsArray) {
  if (url) {
    let newUrl = url;

    for (const paramString of paramsStringsArray) {
      if (paramString) {
        if (newUrl.includes("?")) {
          newUrl = newUrl + `&${paramString}`;
        } else {
          newUrl = newUrl + `?${paramString}`;
        }
      }
    }

    return newUrl;
  } else {
    return url;
  }
}

const replacements = [{ field: "release", replace: "date_added" }];

function sortAdapter(sort) {
  const array = [];

  const split = sort.split("-");

  if (split.length === 2) {
    let sortField = split[0];

    const replacement = replacements.find((x) => x.field === sortField);

    if (replacement) {
      sortField = replacement.replace;
    }

    array.push(`sort=${sortField}`);
    array.push(`order=${split[1]}`);
  }

  return array;
}

