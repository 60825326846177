import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ColorPicker from '../../ColorPicker'
import { Card, CardContent, FormControlLabel, Checkbox, List, FormHelperText, Button, Grid } from '@material-ui/core';
import ImageUploader from '../../ImageUploader';
import { themeActions } from '../../../_actions/theme.actions';
import MenuItemCard from '../../MenuItemCard';
import DragAndDropProvider from '../../../_helpers/DragAndDropProvider';
import update from 'immutability-helper';
import { previewerNavigationActions } from '../../../_actions/previewerNavigation.actions';
import _ from 'lodash';
import DraggableElement from '../../../_helpers/DraggableElement';
import { SCREEN_OPTIONS } from '../../../_helpers/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentTitle: {
    padding: '10px 15px'
  },
  formControl: {
    marginBottom: theme.spacing(3),
  },
  button: {
    margin: '10px 0 20px 0',
  }
}));

export default function DrawerMenu({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(previewerNavigationActions.setDrawerState(data.options.isVisible));
  }, [data.options.isVisible, dispatch]);

  const handleChange = (property, value) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, property, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  const toogleMenuItemVisibility = (menuItemIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData.content[menuItemIndex].isVisible = !newData.content[menuItemIndex].isVisible;
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleMenuItemMove = (dragIndex, hoverIndex) => {
    let contentCopy = JSON.parse(JSON.stringify(data.content));
    const dragSection = contentCopy[dragIndex];

    contentCopy =
      update(contentCopy, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSection],
        ],
      })

    dispatch(themeActions.setSelectedSection({...data, content: contentCopy}));
  }

  const handleMenuItemChange = (values, menuItemIndex, menuItemKey) => {
    let newData = JSON.parse(JSON.stringify(data));

    for(const item of values){
      _.set(newData, `content[${menuItemIndex}][${item.property}]`, item.value)

      const object = _.find(SCREEN_OPTIONS, { key: menuItemKey });
  
      if(object){
        const keys = Object.keys(object);
        for(const key of keys){
          _.set(newData, `content[${menuItemIndex}][${key}]`, object[key])
        }
      }

      switch(item.value){
        case 'goToCategory':
          _.set(newData, `content[${menuItemIndex}][link]`, 'CategoryStack')
          _.set(newData, `content[${menuItemIndex}][relatedScreens]`, null)
          _.set(newData, `content[${menuItemIndex}][key]`, "")
          break;
        case 'goToProduct':
          _.set(newData, `content[${menuItemIndex}][link]`, 'ProductStack')
          _.set(newData, `content[${menuItemIndex}][relatedScreens]`, null)
          _.set(newData, `content[${menuItemIndex}][key]`, "")
          break;
        case 'goToWebview':
          _.set(newData, `content[${menuItemIndex}][link]`, 'WebViewStack')
          _.set(newData, `content[${menuItemIndex}][relatedScreens]`, null)
          _.set(newData, `content[${menuItemIndex}][key]`, "")
          break;
        default:
          break;
      }
    }

    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleMenuItemScreenChange = (key, menuItemIndex) => {
    let newData = JSON.parse(JSON.stringify(data));

    const object = _.find(SCREEN_OPTIONS, { key: key });

    if(object){
      const keys = Object.keys(object);
      for(const key of keys){
        _.set(newData, `content[${menuItemIndex}][${key}]`, object[key])
      }
      
      dispatch(themeActions.setSelectedSection(newData));
    }
  }

  const handleMenuItemIconChange = (icon, menuItemIndex) => {
    let newData = JSON.parse(JSON.stringify(data));

    if(icon){
      _.set(newData, `content[${menuItemIndex}][icon][name]`, icon.name)
      _.set(newData, `content[${menuItemIndex}][icon][style]`, icon.style)

      dispatch(themeActions.setSelectedSection(newData));
    }
  }

  const handleMenuItemDelete = (menuItemIndex) => {
    let newData = JSON.parse(JSON.stringify(data));

    const newContent = newData.content.filter((item, index) => index != menuItemIndex)

    _.set(newData, `content`, newContent)

    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleMenuItemAdd = () => {
    let newData = JSON.parse(JSON.stringify(data));

    const newContent = { 
      title: 'Novo item', 
      isVisible: true, 
      action: 'goToScreen', 
      link: 'HomeStack', 
      icon: {
        name: 'circle',
        style: 'fas'
      },
      key: 'home',
      relatedScreens: ''
    }

    _.set(newData, `content`, [...newData.content, {...newContent}])

    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleUploadSuccess = (uploadData) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData = {...newData, options: { ...newData.options, logo: uploadData }};
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <main className={classes.content}>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
            Configurações
        </Typography>
        
        <Card elevation={1} square>
          <CardContent>

            <FormControlLabel
              control={
                  <Checkbox
                    checked={_.isNil(data.options.isVisible) ? false : data.options.isVisible}
                    onChange={() => handleChange('options.isVisible', !data.options.isVisible)} 
                    name="isVisible"
                    color="primary"
                  />
              }
              label="Exibir menu lateral"
            />

            {data.options.isVisible &&
              <>
                <ColorPicker 
                  label='Cor do texto' 
                  initialColor={data.options.textColor} 
                  onChange={(color) => handleChange('options.textColor', color)} 
                />

                <ColorPicker 
                  label='Cor de fundo' 
                  initialColor={data.options.backgroundColor} 
                  onChange={(color) => handleChange('options.backgroundColor', color)} 
                />

                <FormControlLabel
                  control={
                      <Checkbox
                        checked={_.isNil(data.options.showCategoriesList) ? false : data.options.showCategoriesList}
                        onChange={() => handleChange('options.showCategoriesList', !data.options.showCategoriesList)} 
                        name="showCategoriesList"
                        color="primary"
                      />
                  }
                  label="Exibir lista de categorias"
                />

                <FormControlLabel
                  control={
                      <Checkbox
                        checked={_.isNil(data.options.showLogo) ? false : data.options.showLogo}
                        onChange={() => handleChange('options.showLogo', !data.options.showLogo)} 
                        name="showLogo"
                        color="primary"
                      />
                  }
                  label="Exibir logo"
                />

                {data.options.showLogo && 
                  <>
                    <ImageUploader 
                      imageUrl={_.get(data, 'options.logo.url', null)} 
                      label='Logo' 
                      onUploadSuccess={handleUploadSuccess} 
                    />
                    <FormHelperText>Tamanho recomendado: 420 x 300 px</FormHelperText>
                  </>
                }
              </>
            }
          </CardContent>
        </Card>

        {data.options.isVisible &&
          <>
            <Typography variant="overline" display="block" className={classes.contentTitle}>
              Conteúdo
            </Typography>

            <List>
              <DragAndDropProvider>
                {data.content.map((menuItem, index) => (
                  <DraggableElement hoverIndex={index} key={`mic-${index}`} onMove={handleMenuItemMove}>
                    <MenuItemCard 
                      first={index === 0}
                      menuItem={menuItem}
                      menuItemIndex={index}
                      onVisibilityToogle={() => toogleMenuItemVisibility(index)}
                      onChange={handleMenuItemChange}
                      onChangeMenuItemScreen={handleMenuItemScreenChange}
                      onChangeIcon={handleMenuItemIconChange}
                      onDelete={handleMenuItemDelete}
                    />
                  </DraggableElement>
                ))}
              </DragAndDropProvider>
            </List>

            <Grid container justifyContent="center">
              <Button
                color="default"
                className={classes.button}
                onClick={() => handleMenuItemAdd()}
                startIcon={<FontAwesomeIcon icon={['fas', 'plus-circle']} />}
                variant="contained"
              >
                Adicionar item de menu
              </Button>
            </Grid>
          </>
        }

    </main>
  );
}