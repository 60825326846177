import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  IconButton, ListItemIcon, InputLabel, ListItemSecondaryAction, ListItemText,
  TextField, FormControl, FormHelperText, Select
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import _, { property } from 'lodash';
import ProductPicker from './ProductPicker';
import CategoryPicker from './CategoryPicker';
import { SCREEN_LABELS, SCREEN_OPTIONS } from '../_helpers/constants';
import IconPicker from './IconPicker';
import DeleteButton from './DeleteButton';
import { useSelector } from 'react-redux';

const menuTitleMaxLength = 12

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    paddingBottom: 15,
    paddingTop: 15
  },
  border: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12);'
  },
  dragIcon: {
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing'
    }
  },
  icon: {
    fontSize: '1.5rem',
    textAlign: 'center'
  },
  arrowIcon: {
    fontSize: 16,
    textAlign: 'center'
  },
  actionIcon: {
    fontSize: 20
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    padding: 0,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expandIcon: {
    marginRight: 0,
    order: -1
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: '0px 10px 10px 10px',
    flexDirection: 'column'
  },
}))(MuiAccordionDetails);

export default function MenuItemCard({ cardRef, dragIconRef, menuItem, menuItemIndex, 
  onVisibilityToogle, onChange, onChangeMenuItemScreen, onChangeIcon, onDelete }) {
  const classes = useStyles();
  const storeData = useSelector(state => state.UserReducer.user.store);

  const isWebviewTheme = storeData.activeTheme == 'webview';

  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary
        expandIcon={
          <FontAwesomeIcon 
            icon={['fas', 'chevron-down']} 
            className={classes.arrowIcon} 
            onClick={() => setExpanded(!expanded)}
          />
        }
        classes={{ root: classes.root }}
        ref={cardRef}
      >
        <div style={{ alignItems: 'center', display: 'flex', width: '80%' }}  onClick={() => setExpanded(!expanded)}>
          <ListItemIcon>
            {menuItem.icon && (
              <FontAwesomeIcon icon={[menuItem.icon.style, menuItem.icon.name]} className={classes.icon} />
            )}
          </ListItemIcon>
          
          <ListItemText primary={menuItem.title} />
        </div>
       
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => onVisibilityToogle()}>
            {menuItem.isVisible ?
              <FontAwesomeIcon icon={['fas', 'eye']} className={classes.actionIcon} fixedWidth /> :
              <FontAwesomeIcon icon={['fas', 'eye-slash']} className={classes.actionIcon} fixedWidth />
            }
          </IconButton>
          <IconButton edge="end" className={classes.dragIcon} ref={dragIconRef}>
            <FontAwesomeIcon icon={['fas', 'grip-vertical']} className={classes.actionIcon} fixedWidth />
          </IconButton>
        </ListItemSecondaryAction>
      </AccordionSummary>
      <AccordionDetails key={menuItem.key} className={classes.accordionDetails}>

        <IconPicker menuItem={menuItem} onPicked={(icon) => onChangeIcon(icon, menuItemIndex)} />

        <FormControl fullWidth className={classes.formControl}>
          <TextField
            type="text"
            label="Nome"
            defaultValue={menuItem.title || ''}
            onChange={(e) => onChange([{ property: 'title',value:  e.target.value }], menuItemIndex)}
            inputProps={{ maxLength: menuTitleMaxLength }}
          />
        </FormControl>
        <FormHelperText>Insira o nome que será exibido embaixo do ícone.</FormHelperText>
        <FormHelperText>Caracteres restantes: {menuTitleMaxLength - menuItem.title?.length}.</FormHelperText>

        <FormControl fullWidth className={classes.formControl}>
          <InputLabel>Ação</InputLabel>
          <Select
            native
            value={menuItem.action}
            onChange={(e) => onChange([{ property: 'action', value: e.target.value }], menuItemIndex, menuItem.key)}
            label="Ação"
            inputProps={{ name: 'action' }}
          >
            {!isWebviewTheme && (
              <>
                <option value='goToScreen'>Ir para uma tela do aplicativo</option>
                <option value='goToProduct'>Ir para um produto</option>
                <option value='goToCategory'>Ir para uma categoria</option>
              </>
            )}
            <option value='goToUrl'>Abrir um link externo</option>
            <option value='goToWebview'>Abrir um link externo dentro do aplicativo</option>
            <option value='goToWhatsapp'>Abrir o Whatsapp</option>
          </Select>
          <FormHelperText>Selecione a ação que irá acontecer ao clicar nesse botão.</FormHelperText>
        </FormControl>

        {menuItem.action === 'goToScreen' && (
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel>Tela do aplicativo</InputLabel>
            <Select
              native
              defaultValue={menuItem.key}
              onChange={(e) => onChangeMenuItemScreen(e.target.value, menuItemIndex)}
              label="Tela"
              inputProps={{ name: 'action' }}
            >
              {SCREEN_OPTIONS.map((item, index) => (
                <option key={index} value={item.key}>{SCREEN_LABELS[item.key]}</option>
              ))}
            </Select>
            <FormHelperText>Selecione a tela do aplicativo que será exibida ao clicar no botão.</FormHelperText>
          </FormControl>
        )}

        {menuItem.action === 'goToProduct' &&
          <FormControl fullWidth className={classes.formControl}>
            <ProductPicker
              selectedProductId={menuItem.productId || ''}
              onPicked={(product) => onChange([
                { property: 'productId', value: product.id },
                { property: "productUrl", value: product.url }
              ], menuItemIndex, menuItem.key)}
            />
            <FormHelperText>Selecione o produto que será exibido ao clicar no botão.</FormHelperText>
          </FormControl>
        }

        {menuItem.action === 'goToCategory' &&
          <FormControl fullWidth className={classes.formControl}>
            <CategoryPicker
              selectedCategoryId={menuItem.categoryId || ''}
              onPicked={(category) => onChange(
                [
                  { property:'categoryId', value: category.id },
                  { property:'categoryName', value: category.name },
                ], 
                menuItemIndex, 
                menuItem.key
              )}
            />
            <FormHelperText>Selecione a categoria que será exibida ao clicar no botão.</FormHelperText>
          </FormControl>
        }

        {menuItem.action === 'goToUrl' &&
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label="Link"
              value={menuItem.url || ''}
              onChange={(e) => onChange([{ property: 'url', value: e.target.value }], menuItemIndex, menuItem.key)}
            />
            <FormHelperText>Insira o link que será aberto no navegador do celular ao clicar no botão.</FormHelperText>
            <FormHelperText>Ex.: https://www.minhaloja.com.br/promocao</FormHelperText>
          </FormControl>
        }

        {menuItem.action === 'goToWebview' &&
          <FormControl fullWidth className={classes.formControl}>
            <TextField
              label="Link"
              value={menuItem.url || ''}
              onChange={(e) => onChange([{ property: 'url', value: e.target.value }], menuItemIndex, menuItem.key)}
            />
            <FormHelperText>Insira o link que será aberto no aplicativo ao clicar no botão.</FormHelperText>
            <FormHelperText>Ex.: https://www.minhaloja.com.br/promocao</FormHelperText>
          </FormControl>
        }

        {menuItem.action === 'goToWhatsapp' && (
          <>
            <FormControl fullWidth className={classes.formControl}>
              <TextField
                helperText={"Insira o DDD do número do seu WhatsApp. Ex: 11."}
                label="DDD"
                defaultValue={menuItem.areaCode || ''}
                onChange={(e) => onChange([{ property: 'areaCode', value: e.target.value }], menuItemIndex, menuItem.key)}
              />
            </FormControl>


            <FormControl fullWidth className={classes.formControl}>
              <TextField
                helperText="Insira número do seu WhatsApp. Ex: 99123-8070."
                label="Número"
                defaultValue={menuItem.phoneNumber || ''}
                onChange={(e) => onChange([{ property: 'phoneNumber', value: e.target.value }], menuItemIndex, menuItem.key)}
              />
            </FormControl>
          </>
        )}

        <DeleteButton
          message='Você tem certeza que deseja excluir esse item do menu?'
          buttonText='Excluir item'
          onDelete={() => {
            onDelete(menuItemIndex)
            setExpanded(false)
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}
