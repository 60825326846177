import { store } from '../../index';
import  { PLATFORMS } from '../_helpers/constants';
import { categoriesService as ncs } from '../_services/nuvemshop/categories.service';
import { categoriesService as tcs} from '../_services/tray/categories.service';
import { categoriesService as wcs } from '../_services/wix/categories.wix.service';
import { categoriesService as ics } from '../_services/irroba/categories.service';
import { mapNuvemCategories } from '../_helpers/mappers/nuvemshop';
import { mapTrayCategories } from '../_helpers/mappers/tray';
import { mapWixCategories } from '../_helpers/mappers/wix';
import _ from 'lodash';
import { mapIrrobaCategories } from '../_helpers/mappers/irroba';

export const categoriesActions = {
    getAllCategories
};

function getAllCategories() {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;

    switch(platform){
        case PLATFORMS.NUVEMSHOP:
            queryMethod = ncs.getAllCategories;
            mapperMethod = mapNuvemCategories;
            break;
        case PLATFORMS.TRAY:
            queryMethod = tcs.getAllCategories;
            mapperMethod = mapTrayCategories;
            break;
        case PLATFORMS.WIX:
            queryMethod = wcs.getAllCategories;
            mapperMethod = mapWixCategories;
            break;
        case PLATFORMS.IRROBA:
            queryMethod = ics.getAllCategories;
            mapperMethod = mapIrrobaCategories;
            break;
        default:
            return dispatch => {
                dispatch({ type: "SET_CATEGORIES", payload: {} });
            };
    }
    
    return dispatch => {
        queryMethod().then(response => {
            const categories = platform === PLATFORMS.IRROBA 
                ? mapperMethod(response.data) : mapperMethod(response);
            dispatch({ type: "SET_CATEGORIES", payload: categories });
        })
    };
}