import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import { themeActions } from '../../../../_actions/theme.actions';
import DragAndDropProvider from '../../../../_helpers/DragAndDropProvider';
import ImageAccordion from './ImageAccordion';
import update from 'immutability-helper';
import _ from 'lodash';
import DraggableElement from '../../../../_helpers/DraggableElement';

const useStyles = makeStyles(() => ({
  contentTitle: {
    padding: '10px 15px'
  },
  button: {
    marginTop: 30,
  }
}));

export default function Content({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addBanner = () => {
    let newData = JSON.parse(JSON.stringify(data));
    newData['content'].push({ action: "noAction" })
    dispatch(themeActions.setSelectedSection(newData));
  }

  const deleteBanner = (bannerIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData['content'].splice(bannerIndex, 1);
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleBannerAccordionMove = (dragIndex, hoverIndex) => {
    let bannersCopy = JSON.parse(JSON.stringify(data.content));
    const dragSection = bannersCopy[dragIndex];

    bannersCopy =
      update(bannersCopy, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSection],
        ],
      })

    let newData = { ...data, content: bannersCopy }

    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleChange = (property, value, bannerIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    _.set(newData, `content[${bannerIndex}][${property}]`, value)
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleChangeValues = (values, bannerIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    for(const item of values){
      _.set(newData, `content[${bannerIndex}][${item.property}]`, item.value)
    }
    dispatch(themeActions.setSelectedSection(newData));
  }

  const handleMultipleChange = (changedData, bannerIndex) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData.content[bannerIndex] = {...newData.content[bannerIndex], ...changedData};
    dispatch(themeActions.setSelectedSection(newData));
  }

  return (
    <>
        <Typography variant="overline" display="block" className={classes.contentTitle}>
          Conteúdo
        </Typography>

        <div>
          <DragAndDropProvider>
            {data.content.map((banner, index) => (
              <DraggableElement hoverIndex={index} key={`hs-${index}`} onMove={handleBannerAccordionMove}>
                <ImageAccordion 
                  banner={banner}
                  bannerIndex={index}
                  key={`ba-${index}`} 
                  onChange={handleChange}
                  onChangeValues={handleChangeValues}
                  onDelete={() => deleteBanner(index)}
                  onMove={handleBannerAccordionMove}
                  onMultipleChange={handleMultipleChange}
                />
              </DraggableElement>
            ))}
          </DragAndDropProvider>
        </div>

        <Grid container justify="center">
          <Button
            color="default"
            className={classes.button}
            onClick={() => addBanner()}
            startIcon={<FontAwesomeIcon icon={['fas', 'plus-circle']} />}
            variant="contained"
          >
            Adicionar nova imagem
          </Button>
        </Grid>
    </>
  );
}