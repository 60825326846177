import axios from "axios";
import _ from 'lodash';
import { store } from "../../..";
import { mobilizeiStoresService } from "../mobilizei/stores.service";
import config from "../../_helpers/config";

export const authIrrobaService = {
    refreshAccessToken
};

function refreshAccessToken() {
    const storeData = store.getState().UserReducer.user.store;
    const storeId = storeData.id;

    return axios
        .post(
            `${config.MOBILIZEI_API_BASE_URL}/platforms/irroba/auth/refresh`,
            { storeId }
        )
        .then(() => {
            return mobilizeiStoresService.getStoreById('irroba', storeId).then(storeData => {
                return storeData.accessToken
            })
        })
}