import { store } from "../../..";
import config from "../../_helpers/config";
import { authIrrobaService } from "./auth.service";

export const apiFetcher = (path, init, attempt = 1, returnHeaders = false) => {
    const at = store.getState().UserReducer.user.store.accessToken;

    let url = `${config.MOBILIZEI_API_BASE_URL}/platforms/irroba/api_forward/${path}`;

    if (!init?.headers) init = { ...init, headers: {} }
    init.headers['Authorization'] = at;

    init.headers['Content-Type'] = 'application/json';

    return fetch(url, init).then(response => {
        if (response.status === 204) {
            return;
        }
        return response.json().then(data => {
            if (!response.ok) {
                if (response.status === 401  && attempt <= 3) {
                    return authIrrobaService.refreshAccessToken()
                        .then(accessToken => {
                            store.dispatch({
                                type: "SET_USER_ACCESS_TOKEN",
                                payload: accessToken
                            })
                            return apiFetcher(path, init, attempt + 1, returnHeaders);
                        })
                        .catch((error) => {
                            return Promise.reject(error);
                        });
                }
                const error = data || response.statusText;
                return Promise.reject(error);
            }

            return data;
        })
    })
        .catch(error => {
            if (attempt <= 3) {
                return apiFetcher(path, init, attempt + 1);
            }

            return Promise.reject(error);
        });
};