import { store } from '../../index';
import { PLATFORMS } from '../_helpers/constants';
import { productsService as nps } from '../_services/nuvemshop/products.service';
import { productsService as tps } from '../_services/tray/products.service';
import { productsService as wps } from '../_services/wix/products.wix.service';
import { productsService as ips } from '../_services/irroba/products.service';
import { mapNuvemProduct, mapNuvemProductArrayWithVariants } from '../_helpers/mappers/nuvemshop';
import { mapTrayProduct, mapTrayProductArray } from '../_helpers/mappers/tray';
import _ from 'lodash';
import { mapWixProduct, mapWixProductArrayWithVariants } from '../_helpers/mappers/wix';
import { mapIrrobaProduct, mapIrrobaProductArray } from '../_helpers/mappers/irroba';

export const productsActions = {
    getProducts,
    getProductById,
    searchProducts
};

function getProducts(filters) {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;

    switch (platform) {
        case PLATFORMS.NUVEMSHOP:
            queryMethod = nps.getProducts;
            mapperMethod = mapNuvemProductArrayWithVariants;
            break;
        case PLATFORMS.TRAY:
            queryMethod = tps.getProducts;
            mapperMethod = mapTrayProductArray;
            break;
        case PLATFORMS.WIX:
            queryMethod = wps.getProducts;
            mapperMethod = mapWixProductArrayWithVariants;
            break;
        case PLATFORMS.IRROBA:
            queryMethod = ips.getProducts;
            mapperMethod = mapIrrobaProductArray;
            break;
        default:
            break;
    }

    return () => {
        return queryMethod(filters).then(response => {
            let data = null;

            switch (platform) {
                case PLATFORMS.NUVEMSHOP:
                    data = {
                        products: mapperMethod(response.data),
                        totalCount: response.headers.get('x-total-count')
                    }
                    break;
                case PLATFORMS.TRAY:
                    data = {
                        products: mapperMethod(response.Products),
                        totalCount: _.get(response, 'paging.total', 0)
                    }
                    break;
                case PLATFORMS.WIX:
                    data = {
                        products: mapperMethod(response.data.products),
                        totalCount: _.get(response, 'data.totalResults', 0)
                    }
                    break;
                case PLATFORMS.IRROBA:
                    data = {
                        products: mapperMethod(response.data),
                        totalCount: response.pagination.nextPageUrl ? 100 : response.pagination.lastItem
                    }
                    break;
                default:
                    break;
            }

            return data;
        })
    };
}

function getProductById(productId) {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;

    switch(platform){
        case PLATFORMS.NUVEMSHOP:
            queryMethod = nps.getProductById;
            mapperMethod = mapNuvemProduct;
            break;
        case PLATFORMS.TRAY:
            queryMethod = tps.getProductById;
            mapperMethod = mapTrayProduct;
            break;
        case PLATFORMS.WIX:
            queryMethod = wps.getProductById;
            mapperMethod = mapWixProduct;
            break;
        case PLATFORMS.IRROBA:
            queryMethod = ips.getProductById;
            mapperMethod = mapIrrobaProduct;
            break;
        default:
            break;
    }

    return () => {
        return queryMethod(productId).then(response => {
            let data = null;

            switch (platform) {
                case PLATFORMS.WIX:
                    data = mapperMethod(response.product);
                    break;
                case PLATFORMS.IRROBA:
                    if (response.data?.length > 0) {
                        data = mapperMethod(response.data[0])
                    }
                    break;
                default:
                    data = mapperMethod(response)
                    break;
            }

            return data
        })
    };
}

function searchProducts(filters, query) {
    const platform = store.getState().StoreReducer.platform;

    let queryMethod = null;
    let mapperMethod = null;

    switch(platform){
        case PLATFORMS.NUVEMSHOP:
            queryMethod = nps.searchProducts;
            mapperMethod = mapNuvemProductArrayWithVariants;
            break;
        case PLATFORMS.TRAY:
            queryMethod = tps.searchProducts;
            mapperMethod = mapTrayProductArray;
            break;
        case PLATFORMS.WIX:
            queryMethod = wps.getProducts;
            mapperMethod = mapWixProductArrayWithVariants;
            break;
        case PLATFORMS.IRROBA:
            queryMethod = ips.getProducts;
            mapperMethod = mapIrrobaProductArray;
            break;
        default:
            break;
    }

    return () => {
        return queryMethod(filters, query).then(response => {
            let data = null;

            switch(platform){
                case PLATFORMS.NUVEMSHOP:
                    data = {
                        products: mapperMethod(response.data),
                        totalCount: response.headers.get('x-total-count')
                    }
                    break;
                case PLATFORMS.TRAY:
                    data = {
                        products: mapperMethod(response.Products),
                        totalCount: _.get(response, 'paging.total', 0)
                    }
                    break;
                case PLATFORMS.WIX:
                    data = {
                        products: mapperMethod(response.data.products),
                        totalCount: _.get(response, 'data.totalResults', 0)
                    }
                    break;
                case PLATFORMS.IRROBA:
                    data = {
                        products: mapperMethod(response.data),
                        totalCount: response.pagination.nextPageUrl ? 100 : response.pagination.lastItem
                    }
                    break;
                default:
                    break;
            }

            return data;
        })
    };
}