import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ProductHalfCard from '../ProductHalfCard/ProductHalfCard';
import ScrollContainer from 'react-indiana-drag-scroll'
import _ from 'lodash';
import shortid from 'shortid';
import { productsActions } from '../../../Editor/_actions/products.actions';
import { PLATFORMS } from '../../../Editor/_helpers/constants';

const useStyles = makeStyles(() => ({
  root: props =>({
    cursor: 'grab',
    display: props.isVisible ? 'block' : 'none',
    width: '100%'
  }),
  title: props =>({
    color: props.options.carouselTitleColor,
    fontSize: 18,
    fontWeight: 'bold',
    margin: 0,
    padding: '10px 5px 0 5px',
    textAlign: props.options.textAlign
  }),
  subtitle: props =>({
    color: props.options.carouselSubtitleColor,
    fontSize: 15,
    margin: 0,
    padding: '0 5px',
    textAlign: props.options.textAlign
  }),
  content: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      width: '100%'
  }
}));

export default function ProductCarousel({ data }) {
  const dispatch = useDispatch();
  const classes = useStyles(data);
  const platform = useSelector(state => state.StoreReducer.platform);
  const numberOfProducts = _.get(data, 'options.numberOfProducts', 6);

  const getEmptyProductsArray = () => {
    const idBase = shortid.generate();
    return Array.from({ length: parseInt(numberOfProducts) }, (_, i) => { return { id: `${idBase}-${i}`} } )
  }

  const [products, setProducts] = useState(getEmptyProductsArray());

  useEffect(() => {
    if(_.isEmpty(_.toString(data.options.categoryId))) {
      setProducts(getEmptyProductsArray());
      return;
    }

    const defaultSorting = _.get(data, 'options.defaultSorting', null);

    let filters = {};

    switch(platform){
      case PLATFORMS.TRAY:
        filters = {
          limit: numberOfProducts,
          sort: _.isEmpty(defaultSorting) ? 'release_desc' : defaultSorting
        };
        break;
      case PLATFORMS.IRROBA:
        filters = {
          per_page: 3,
          sort_by: _.isEmpty(defaultSorting) ? 'release-desc' : defaultSorting
        };
        break;
      default:
        filters = {
          per_page: numberOfProducts,
          sort_by: _.isEmpty(defaultSorting) ? 'created-descending' : defaultSorting
        };
        break;
    }

    filters['category_id'] = data.options.categoryId;

    dispatch(productsActions.getProducts(filters))
      .then(response => {
        setProducts(response.products);
      })
      .catch(() => {
        setProducts(getEmptyProductsArray());
      })
  }, [data.options.categoryId, data.options.defaultSorting, data.options.numberOfProducts]);

  return (
    <div className={classes.root}>
        <p className={classes.title}>{data.options.carouselTitle}</p>
        <p className={classes.subtitle}>{data.options.carouselSubtitle}</p>

        <ScrollContainer className={classes.content}>
            {products.map(product => {
              return <ProductHalfCard key={product.id} product={product} />
            })}
        </ScrollContainer>
    </div>
  );
}