import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import PriceArea from './PriceArea';
import DescriptionContainer from './DescriptionContainer';
import QuantitySelector from './QuantitySelector';
import ProductVariantsArea from './ProductVariantsArea';
import BuyButtonArea from './BuyButtonArea';
import WishlistButton from "../_components/Sections/WishlistButton"
import { getSelectedArea } from '../../Editor/_reducers/theme.reducer';
import { productsActions } from '../../Editor/_actions/products.actions';
import _ from 'lodash';
import { PLATFORMS } from '../../Editor/_helpers/constants';
import Shipping from '../_components/Shipping/Shipping';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: '#fff',
        cursor: 'grab',
        position: 'relative',
        width: '100%'
    },
    title: props =>({
      color: props.titleColor,
      fontSize: 18,
      padding: '0px 20px',
      textAlign: "center"
    }),
    slider: props =>({
        '& .slick-dots': {
          display: "block!important",
          bottom: 10,
          '& li button:before': {
            color: `${props.pagerColor}!important`,
            opacity: 1
          },
          '& li.slick-active button:before': {
              color: `${props.activePagerColor}!important`,
          }
        }
    })
}));

export default function ProductScreen() {
  const dispatch = useDispatch();
  const selectedArea = useSelector(state => getSelectedArea(state));
  const data = selectedArea.sections.find(s => s.key === 'productDetailsSettings');
  const sections = _.get(data, 'sections', []);
  const settings = sections.find(s => s.key === 'productDetailsSettings');
  const options = _.get(settings, 'options', {});

  const platform = useSelector(state => state.StoreReducer.platform);

  const colors = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'colors'));
  const classes = useStyles({...data.options, ...colors.options});

  const [product, setProduct] = useState({});
  

  useEffect(() => {
    const defaultSorting = _.get(data, 'options.defaultSorting', null);

    let filters = {};

    switch(platform){
      case PLATFORMS.TRAY:
        filters = {
          limit: 1,
          sort: _.isEmpty(defaultSorting) ? 'release_desc' : defaultSorting
        };
        break;
      case PLATFORMS.IRROBA:
        filters = {
          per_page: 1,
          sort_by: _.isEmpty(defaultSorting) ? 'release-desc' : defaultSorting
        }
        break;
      default:
        filters = {
          per_page: 1,
          sort_by: _.isEmpty(defaultSorting) ? 'created-descending' : defaultSorting
        };
        break;
    }

      dispatch(productsActions.getProducts(filters))
        .then(response => {
          setProduct(response.products[0]);
        })
        .catch(() => {
          setProduct({});
        })
  }, []);

  const getImages = () => {
    if(_.isEmpty(product.images)) {
      return (
        [1,2,3].map((banner, index) => {
          return <img key={`banner-${index}`} src={require('../../Editor/_assets/images/image_placeholder_350_350.png')} className={classes.image} alt="banner"/>
        })
      )
    }

    return (
      product.images.map((image, index) => {
        return <img key={`banner-${index}`} src={image.imageUrl} className={classes.image} alt="banner"/>
      })
    )
  }

  return (
    <div className={classes.root}>
        <p className={classes.title}>{_.get(product, 'name', 'Título do produto')}</p>

        <div style={{position: 'relative'}}>
          <Slider 
            autoplay={data.options.autoplay}
            autoplaySpeed={parseInt(_.get(data, 'options.autoplaySpeed', 2000))}
            className={classes.slider} 
            dots={data.options.showPager}

            // We are setting the key like this to force the component to rerender when 
            // autoplay changes, otherwise the autoplay dynamically selection will not work
            key={data.options.autoplay} 
          >
              {getImages()}
          </Slider>
          <WishlistButton screenType={"ProductScreen"} />
        </div>
        
        <PriceArea colors={colors} data={data} product={product} />
            
        {data.options.showQuantitySelector && <QuantitySelector colors={colors} />}

        <ProductVariantsArea />

        {data.options.showShipping && (
          <Shipping colors={colors} products={[product]} enableSummary={false} />
        )}

        <DescriptionContainer colors={colors} product={product} />

        <BuyButtonArea colors={colors} />
    </div>
  );
}
