import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ProductHalfCard from '../../_components/ProductHalfCard/ProductHalfCard';
import ProductFullCard from '../../_components/ProductFullCard/ProductFullCard';
import { productsActions } from '../../../Editor/_actions/products.actions';
import _ from 'lodash';
import shortid from 'shortid';
import { PLATFORMS } from '../../../Editor/_helpers/constants';

const useStyles = makeStyles(() => ({
    root: props =>({
        backgroundColor: '#fff',
        cursor: 'grab',
        display: props.isVisible ? 'flex' : 'none',
        flexDirection: 'column',
        width: '100%'
    }),
    title: props =>({
        color: props.options.titleColor,
        fontSize: 18,
        fontWeight: 'bold',
        margin: 0,
        padding: '10px 5px 0 5px',
        textAlign: props.options.textAlign
      }),
      subtitle: props =>({
        color: props.options.subtitleColor,
        fontSize: 15,
        margin: 0,
        padding: '0 5px',
        textAlign: props.options.textAlign
      }),
    icon: {
        height: 22,
        marginLeft: 20,
        width: 22
    },
    productCardsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: "wrap",
    },
    button: props =>({
        alignSelf: 'center',
        backgroundColor: props.buttonColor,
        borderRadius: 4,
        bottom: 0,
        margin: '10px 0 20px 0',
        padding: '8px 14px',
    }),
    buttonText: props =>({
        color: props.buttonTextColor,
        fontSize: 14,
        margin: 0,
        textAlign: 'center'
    }),
}));

export default function ProductList({ data }) {
  const colors = useSelector(state => state.ThemeReducer.settings.find(s => s.key === 'colors'));

  const dispatch = useDispatch();
  const classes = useStyles({...data, ...colors.options});
  const platform = useSelector(state => state.StoreReducer.platform);

  const numberOfProducts = _.get(data, 'options.numberOfProducts', 6);

  const getEmptyProductsArray = () => {
    const idBase = shortid.generate();
    return Array.from({ length: numberOfProducts }, (_, i) => { return { id: `${idBase}-${i}`} } )
  }

  const [products, setProducts] = useState(getEmptyProductsArray());

  useEffect(() => {
    const defaultSorting = _.get(data, 'options.defaultSorting', null);

    let filters = {};

    switch(platform){
        case PLATFORMS.TRAY:
            filters = {
                limit: numberOfProducts,
                sort: _.isEmpty(defaultSorting) ? 'release_desc' : defaultSorting
            };
            break;
        case PLATFORMS.IRROBA:
            filters = {
                per_page: numberOfProducts,
                sort_by: _.isEmpty(defaultSorting) ? 'release-desc' : defaultSorting
            }
            break;
        default:
            filters = {
                per_page: numberOfProducts,
                sort_by: _.isEmpty(defaultSorting) ? 'created-descending' : defaultSorting
            };
            break;
    }

    if(data.options.productsOrigin === 'category') {
        // The filter 'category_id' is the same for Tray and Nuvemshop
        filters['category_id'] = data.options.categoryId;
    }

    dispatch(productsActions.getProducts(filters))
        .then(response => {
            setProducts(response.products);
        })
        .catch(() => {
            setProducts(getEmptyProductsArray());
        })
  }, [data.options.defaultSorting, data.options.numberOfProducts, data.options.productsOrigin, data.options.categoryId, dispatch]);

  return (
    <div className={classes.root}>
        <p className={classes.title}>{data.options.title}</p>
        <p className={classes.subtitle}>{data.options.subtitle}</p>

        <div className={classes.productCardsWrapper}>
            {products.map(product => {
                if(data.options.defaultView === 'grid') {
                    return <ProductHalfCard key={`phc-${product.id}`} product={product} />
                } else {
                    return <ProductFullCard key={`pfc-${product.id}`} product={product} />
                }
            })}
        </div>

        {data.options.showButton &&
            <div className={classes.button}>
                <p className={classes.buttonText}>
                    {_.get(data, 'options.buttonText', 'Ver mais')}
                </p>
            </div>
        }
    </div>
  );
}
